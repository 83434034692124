import React from "react"
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import PageNotFound from "../components/404.js"

export default () => (
    <div>
        <Header/>
        <PageNotFound/>
        <Footer />
    </div>
)