import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import SEO from "./seo.js"
import "./about.css";
import { MdFindInPage } from "react-icons/md";

export default () => (
    <div className="about-content">

        <SEO
            title="Page Not Found"
            description="Page Not Found"
        />

        <Container>
            <Row>
                <Col>
                    <p><b>-------------------------------------------</b></p>
                    <h1>Page Not Found        <MdFindInPage size={50}/></h1>
                    <p><b>-------------------------------------------</b></p>
                </Col>
            </Row>
        </Container>

    </div>
)